/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import groupBy from "lodash/groupBy";

import { Layout, NewsList, PageHeading } from "components";

function NewsPage({
  data: {
    allSanityNewsArticle: { newsArticles },
  },
}) {
  const newsArticlesByYear = groupBy(
    newsArticles.map(({ node }) => node),
    "year"
  );

  const years = Object.keys(newsArticlesByYear).sort((a, b) => b - a);

  return (
    <Layout pageTitle="News">
      <PageHeading heading="News" />

      {years.map((year) => (
        <NewsList
          key={year}
          id={year}
          name={year}
          heading={year}
          newsArticles={newsArticlesByYear[year]}
        />
      ))}
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanityNewsArticle(
      sort: { order: DESC, fields: date }
      filter: { date: { ne: null } }
    ) {
      newsArticles: edges {
        node {
          _id
          date(formatString: "DD.MM.YYYY")
          year: date(formatString: "YYYY")
          title
          image {
            asset {
              gatsbyImageData(width: 600)
            }
          }
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export default NewsPage;
